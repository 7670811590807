import { MixpanelCustomEvent, MixpanelPageViewEvent } from '@xylabs/react-pixel'
import type { EmptyObject } from '@xylabs/sdk-js'
import type { Mixpanel } from 'mixpanel-browser'

export class MixpanelEvents<T extends EmptyObject = EmptyObject> {
  constructor(protected mixpanel: Mixpanel) {}
  custom<T extends EmptyObject>(name: string) {
    return new MixpanelCustomEvent<T>(name, this.mixpanel)
  }

  pageview<T extends EmptyObject>() {
    return new MixpanelPageViewEvent<T>(this.mixpanel)
  }

  purchase<T extends EmptyObject>() {
    return new MixpanelCustomEvent<T>('Purchase', this.mixpanel)
  }

  userClick<T extends EmptyObject>() {
    return new MixpanelCustomEvent<T>('UserClick', this.mixpanel)
  }

  viewContent<T extends EmptyObject>() {
    return new MixpanelCustomEvent<T>('ViewContext', this.mixpanel)
  }
}
